import React, { useState, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import ColorModeContext from "../components/ColorModeContext";
import { Constants } from "../constants";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ComputerIcon from '@mui/icons-material/Computer';
import CustomButton from "../components/CustomButton";
import Divider from "@mui/material/Divider";
import UserAvatar from "../components/UserAvatar";
import Sidebar from "./Sidebar";

interface Props {
    onSideBarOpen: () => void;
    children?: React.ReactNode;
}

const Header: React.FC<Props> = ({ onSideBarOpen }) => {
    const theme = useTheme();

    return (
        <React.Fragment>
            <AppBar
                elevation={5}
                sx={{
                    top: 0,
                    border: 0,
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.secondary
                }}
            >
                <Toolbar sx={{ minHeight: 70 }}>
                    {/* "xs" == phone, 'sm' == tablet, "md" == desktop/laptop, 'lg' == larger desktops */}
                    <Box alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
                        <Sidebar></Sidebar>
                        <IconButton size="large" disabled>
                            {/* To add on Fx Logo here */}
                            <Typography
                                variant="h3"
                                //component="div"
                                sx={{
                                    flexGrow: 1,
                                    color: theme.palette.text.primary,
                                    fontWeight: "bold",
                                }}
                            >
                                {Constants.Layout.APP_TITLE}
                            </Typography>
                        </IconButton>
                    </Box>
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <Box>
                            <IconButton size="large" disabled>
                                {/* To add on Fx Logo here */}
                                <Typography
                                    variant="h3"
                                    //component="div"
                                    sx={{
                                        flexGrow: 1,
                                        color: theme.palette.text.primary,
                                        fontWeight: "bold",
                                        display: {
                                            sm: "inline",
                                            xs: "none"
                                        }
                                    }}
                                >
                                    {Constants.Layout.APP_TITLE}
                                </Typography>
                            </IconButton>
                        </Box>
                    </Link>
                    <Box sx={{ flexGrow: 1 }} />
                    {/* Hides header buttons except theme toggler, and avatar*/}
                    <Box alignItems="center" sx={{ display: { xs: "none", sm: "flex" } }}>
                        <CustomButton link="/" external={false} icon={<DashboardRoundedIcon />} text={Constants.Layout.DASHBOARD} />
                        <CustomButton link="https://starscan.io/" external={true} icon={<ComputerIcon />} text={Constants.Layout.EXPLORER} />
                    </Box>
                    <Divider
                        orientation="vertical"
                        sx={{ height: 32, mx: 2, display: { lg: "flex", md: "none", xs: "none" } }}
                    />
                    <Box sx={{ display: { sm: "flex", xs: "none" } }}>
                        <UserAvatar showAddress={true} />
                    </Box>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}

export default Header;