import Logo from '../assets/svg/logo_white.b812bb59.svg'

export const Loader = () => {
    return (
        <div className='back-drop'>
            <div className='loader-container'>
                <img src={Logo}></img>
            </div>
        </div>

    );
}
