//NOTE: Always manually check with the CMC API if the token is supported.
//For instance, BAVA, FXG (FoxGaming), NOMO do not have data on CMC

export const BAVA_LOGO = "https://raw.githubusercontent.com/YP010/FXSwap-TokenList/main/Tokens/0xc8B4d3e67238e38B20d38908646fF6F4F48De5EC/logo.png";
export const FOXGAMING_LOGO = "https://raw.githubusercontent.com/YP010/FXSwap-TokenList/main/Tokens/0xebd3B8b345A325B0fcE339aeEa9996A6a5a947dc/logo.png"

export enum Logos {
    FX = "https://s2.coinmarketcap.com/static/img/coins/64x64/3884.png",
    PUNDIX = "https://s2.coinmarketcap.com/static/img/coins/64x64/9040.png",
    WETH = "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
    USDT = "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    PURSE = "https://raw.githubusercontent.com/YP010/FXSwap-TokenList/main/Tokens/0x5FD55A1B9FC24967C4dB09C513C3BA0DFa7FF687/logo.png",
    DAI = "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
    C98 = "https://s2.coinmarketcap.com/static/img/coins/64x64/10903.png",
    BAVA = "https://raw.githubusercontent.com/YP010/FXSwap-TokenList/main/Tokens/0xc8B4d3e67238e38B20d38908646fF6F4F48De5EC/logo.png",
    FXG = "https://raw.githubusercontent.com/YP010/FXSwap-TokenList/main/Tokens/0xebd3B8b345A325B0fcE339aeEa9996A6a5a947dc/logo.png",
    EURS = "https://s2.coinmarketcap.com/static/img/coins/64x64/2989.png",
    BUSD = "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png",
    FRAX = "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png",
    LINK = "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png",
    USDC = "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    AVAX = "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
    BNB = "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    MATIC = "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    ETH = "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    BTC = "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    WBTC = "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png",
}

export enum SupportedTokenSymbols {
    FX = "FX",
    PUNDIX = "PUNDIX",
    WETH = "WETH",
    USDT = "USDT",
    PURSE = "PURSE",
    DAI = "DAI",
    C98 = "C98",
    // BAVA = "BAVA",
    // FXG = "FXG",
    EURS = "EURS",
    BUSD = "BUSD",
    FRAX = "FRAX",
    LINK = "LINK",
    USDC = "USDC",
    AVAX = "AVAX",
    BNB = "BNB",
    MATIC = "MATIC",
    ETH = "ETH",
    BTC = "BTC",
    WBTC = "WBTC",
}