import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import authReducer from "./authSlice";
import application from "./application/applicationReducer";
import tokens from "./tokens/tokensReducer"
import prices from "./prices/pricesReducer"
import farms from "./liquidityFarms/farmReducer";
import metrics from "./metrics/metricsReducer"
import { updateVersion } from "./global/actions";
import { blockNumberMiddleware } from "./middleware/blockNumber";
import { userMiddleware } from "./middleware/user";
import privateNotesReducer from "./privateNote/privateNoteSlice"
import alertReducer from "./alertSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        auth: authReducer,
        application,
        tokens,
        prices,
        farms,
        privateNotes: privateNotesReducer,
        metrics,
        alert: alertReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            blockNumberMiddleware.middleware,
            userMiddleware.middleware
        ]),
});

store.dispatch(updateVersion());

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch