import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './App.css';
import { Constants } from "./constants";
import Layout from './layout/Layout';
import ColorModeContext from './components/ColorModeContext';
import { theme as CustomTheme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Dashboard from './pages/Dashboard';
import AuthGuard from './components/AuthGuard';
import Login from './pages/Login';
import Create from './pages/Create';
import NotFound from './pages/NotFound';
import Web3ReactManager from './components/Web3ReactManager';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { configureChains, WagmiConfig, createClient } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { useAppDispatch, useAppSelector } from './state/ReduxHooks';
import serveraxios from 'config/ServerAxios';
import { selectAuth, setAccessToken } from './state/authSlice'
import { Profile } from 'pages/Profile';
import { selectUser, fetchAndUpdateUser } from 'state/userSlice';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import Test from 'pages/Test';


//Mandatory due to CRA issues with WalletConnect libraries
window.Buffer = window.Buffer || require("buffer").Buffer;

const queryClient = new QueryClient();

const { chains, provider, webSocketProvider } = configureChains(
  [Constants.Chain.FXCORE],
  [publicProvider()],
)

const wagmiClient = createClient({
  autoConnect: true,
  provider,
  connectors: [
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "Portfolio X",
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
        bridge: `https://wallet-connect.pundix.com/bridge/`
      },
    }),
    // new InjectedConnector({
    //   chains,
    //   options: {
    //     name: "Injected",
    //     shimDisconnect: true,
    //   },
    // }),
    new MetaMaskConnector({
      chains,
      options: {
        UNSTABLE_shimOnConnectSelectAccount: true,
      },
    })
  ],
  webSocketProvider
})

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const auth = useAppSelector(selectAuth)
  const [themeMode, setThemeMode] = useState("dark");
  const colorMode = useMemo(() => ({
    //Switching themes will call this
    toggleColorMode: () => {
      window.localStorage.setItem("themeMode", themeMode === "dark" ? "light" : "dark");
      setThemeMode((prev) => (prev === "dark" ? "light" : "dark"));
    }
  }), [themeMode]);

  useEffect(() => {
    try {
      const localTheme = window.localStorage.getItem("themeMode");
      localTheme ? setThemeMode(localTheme) : setThemeMode("dark")
    } catch {
      setThemeMode("dark");
    }
  }, []);

  const getAccessTokenAndRefreshToken = useCallback(async (): Promise<NodeJS.Timeout> => {
    try {
      const response = await serveraxios.post(
        `/api/refreshToken`,
        undefined,
        { withCredentials: true }
      );
      console.log(response);
      if (response.status === 200) {
        dispatch(setAccessToken(response.data.accessToken))
      }
    } catch (err) {
      console.error(err)
      dispatch(setAccessToken(null))
    }

    return setTimeout(getAccessTokenAndRefreshToken, 15 * 60 * 1000)

  }, [])

  useEffect(() => {
    const timeoutId = getAccessTokenAndRefreshToken()
    return () => {
      timeoutId as unknown as number
    }
  }, [setAccessToken])

  useEffect(() => {
    if (auth.accessToken !== null && auth.accessToken !== 'fetching' && !user.name) {
      dispatch(fetchAndUpdateUser())
    }
  }, [auth.accessToken])

  return (
    <HelmetProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={CustomTheme[themeMode as keyof typeof CustomTheme]}>
          <CssBaseline>
            <BrowserRouter>
              <Layout>
                <QueryClientProvider client={queryClient}>
                  <Web3ReactManager>
                    <WagmiConfig client={wagmiClient}>
                      <Routes>
                        <Route path="/" element={<AuthGuard><Dashboard /></AuthGuard>} />
                        <Route path="/profile" element={<AuthGuard><Profile /></AuthGuard>} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/create" element={<Create />} />
                        <Route path="/test" element={<Test />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </WagmiConfig>
                  </Web3ReactManager>
                </QueryClientProvider>
              </Layout>
            </BrowserRouter>
          </CssBaseline>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </HelmetProvider>
  );
}

export default App;
