import * as Address from "./Addresses";
import * as ChainId from "./ChainIds";
import * as Endpoint from "./Endpoints";
import * as Layout from "./Layout";
import * as Auth from "./Auth"
import * as Misc from "./Misc";
import * as Errors from "./Errors";
import * as Create from "./Create";
import * as Wallet from "./Wallet";
import * as Tokens from "./Tokens"
import * as Chain from "./Chain";
import * as Farms from "./Farms";
import * as UniswapV3 from "./UniswapV3";
import * as FxFarmsSnapshot from "./FxSwapFarmsSnapshot";
import * as Data from "./Data";
import * as MarginX from "./MarginX";

export const Constants = { 
    Address, 
    ChainId, 
    Chain, 
    Endpoint, 
    Layout, 
    Auth, 
    Misc, 
    Errors, 
    Create, 
    Wallet, 
    Tokens, 
    Farms, 
    UniswapV3,
    FxFarmsSnapshot,
    Data,
    MarginX,
};