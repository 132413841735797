import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, Link, Grid, Typography, Alert } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Constants } from "../constants";
import { useAppSelector, useAppDispatch } from "../state/ReduxHooks"
import { setUser } from "../state/userSlice";
import { selectAuth, login, LoginSuccess } from "../state/authSlice";
import Web3Wallet from "../components/Web3Wallet";


export interface LoginData {
    email: string;
    password: string;
}

const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const [signInError, setSignInError] = useState<boolean>(false);
    const [web3WalletError, setWeb3WalletError] = useState<boolean>(false);
    const [web3WalletErrorMsg, setWeb3WalletErrorMsg] = useState<string>("");
    const auth = useAppSelector(selectAuth)

    const renderSigninError = () => {
        if (signInError) {
            return (
                <Box paddingTop={2.5}>
                    <Alert variant="outlined" severity="error">
                        {Constants.Auth.FAILED_SIGN_IN}
                    </Alert>
                </Box>
            );
        } else {
            return (
                <div />
            );
        }
    }

    const renderWeb3WalletError = () => {
        if (web3WalletError) {
            return (
                <Box paddingTop={2.5}>
                    <Alert variant="outlined" severity="error">
                        {web3WalletErrorMsg}
                    </Alert>
                </Box>
            )
        } else {
            return (
                <div />
            )
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData: any = new FormData(event.currentTarget);
        const loginData: LoginData = {
            email: formData.get("email"),
            password: formData.get("password"),
        }
        try {
            const res: any = await dispatch(login(loginData));
            //if type is auth/login/fufilled...
            const resType: string = res.type;
            if (resType === "auth/login/fulfilled") {
                const resPayload: LoginSuccess = res.payload;
                setSignInError(false);
                dispatch(setUser(resPayload.user))
            }
            else {
                setSignInError(true);
            }
        }
        catch (error: any) {
            console.log(error.message);
        }
    }

    const handleCreateAccount = () => {
        navigate("/Create");
    }

    useEffect(()=>{
        if(auth.accessToken && auth.accessToken!=='fetching'){
            navigate("/")
        }
    },[auth.accessToken])

    return (
        <Box
            sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold"
                }}
            >
                {Constants.Auth.LOGIN_HEADER}
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    color: theme.palette.text.secondary
                }}
            >
                {Constants.Auth.LOGIN_SUBHEADER}
            </Typography>
            {renderSigninError()}
            {renderWeb3WalletError()}
            <Box>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {Constants.Auth.SIGN_IN}
                    </Button>
                </Box>
                <Web3Wallet
                    setError={setWeb3WalletError}
                    setErrorMsg={setWeb3WalletErrorMsg}
                />
                <Grid container>
                    <Grid item xs>
                        <Link variant="body2">
                            {Constants.Auth.FORGOT_PASSWORD}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link onClick={handleCreateAccount} variant="body2">
                            {Constants.Auth.SIGN_UP}
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Login;