import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useAppSelector } from "../state/ReduxHooks"
import Portfolio from "../components/Portfolio";
import useDebounce from "../hooks/useDebounce";
import { retrieveLiquidityPosMeta } from "../utils/Liquidity";
import { retrieveFarmPosMeta } from "../utils/Farm";
import DefiSnapshot from "../components/DefiSnapshot";
import PrivateNote from "components/PrivateNote";


const Dashboard: React.FC = () => {
    const userAddress = useAppSelector((state) => state.user.user.walletAddress);
    const tokensState = useAppSelector((state) => state.tokens);
    const pricesState = useAppSelector((state) => state.prices);
    const farmsState = useAppSelector((state) => state.farms);
    const debouncedTokenState = useDebounce(tokensState, 1000);
    const debouncedPricesState = useDebounce(pricesState, 1000);
    const debouncedFarmState = useDebounce(farmsState, 1000);

    const [updatedLiquidityPos, setUpdatedLiquidityPos] = useState<UpdatedLiquidityPositions>({
        userAddress: null,
        liquidityPositions: null
    });

    const [updatedFarmPos, setUpdatedFarmPos] = useState<UpdatedFarmPositions>({
        userAddress: null,
        farms: null
    })

    useEffect(() => {
        const liquidityData = retrieveLiquidityPosMeta(debouncedTokenState, debouncedPricesState)
        if (liquidityData) {
            setUpdatedLiquidityPos(liquidityData)
        }
    }, [debouncedTokenState, debouncedPricesState]);

    useEffect(() => {
        const farmData = retrieveFarmPosMeta(debouncedFarmState, debouncedPricesState, userAddress)
        if (farmData) {
            setUpdatedFarmPos(farmData);
        }
    }, [debouncedFarmState, debouncedPricesState, userAddress]);

    return (
        <Box>
            <Box
                sx={{
                    display: "block",
                    paddingLeft: "36px",
                    paddingRight: "36px",
                    marginTop: "36px",
                }}>
                {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        sx={{
                            color: "primary"
                        }}
                    >
                        {"Refresh"}
                    </Button>
                </Box> */}
                {/* <Box>
                    <Overview
                        totalAssetsValue={0}
                        claimableValue={0}
                        positions={0}
                    />
                </Box>
                <Box sx={{ paddingTop: "50px" }}>
                    <Wallet></Wallet>
                </Box> */}
                <Box sx={{ paddingTop: "30px", paddingBottom: "30px" }}>
                    <Portfolio
                        liquidityData={updatedLiquidityPos}
                        farmsData={updatedFarmPos}
                    ></Portfolio>
                </Box>
                <Box sx={{ paddingTop: "30px", paddingBottom: "30px" }}>
                    <PrivateNote />
                </Box>
                <Box sx={{ paddingTop: "30px", paddingBottom: "30px" }}>
                    <DefiSnapshot />
                </Box>
            </Box>
        </Box>
    )
}

export default Dashboard;