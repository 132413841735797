import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from "@mui/material/TableBody";
import { StyledTableRow } from "../DataTable";
import { StyledTableCell } from "../DataTable";
import TableCell from "@mui/material/TableCell";
import { Link, Paper } from "@mui/material";
import { theme } from "../../theme";
import LPIcon from "../LPIcon";
import { FarmGroup } from "./PortfolioData";
import LinearProgress from "@mui/material/LinearProgress";

const FxSwapFarmGroup: React.FC<FarmGroup> = ({ group, headings, data }) => {
    const [isLoading, setIsLoading] = useState(true);

    const modifyValueString = (str: string) => {
        const indexOfDot = str.indexOf(".")
        return str.slice(0, indexOfDot + 6)
    }

    useEffect(() => {
        const interval = setTimeout(() => setIsLoading(false), 15000);
        return () => clearTimeout(interval);
    }, []);
    
    const renderTableBody = () => {
        if (data.length === 0 || !data[0].id) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={6} sx={{ textAlign: "center", padding: 0, borderBottom: 0 }}>
                            <Paper sx={{ borderRadius: 0 }}>
                                <Typography variant="body2">
                                    {"----You do not have any ongoing positions - head to "}
                                    <Link href="https://fx-swap.io/#/farm">Fx Swap</Link>
                                    {" to get started----"}
                                </Typography>
                            </Paper>
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }
        return (
            <TableBody>
                {data.map((row, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <LPIcon
                                    token0Src={row.token0LogoUrl}
                                    token1Src={row.token1LogoUrl}
                                />
                                <Box sx={{ display: "block" }}>
                                    <Box>
                                        <Typography variant="subtitle1">
                                            {row.farmName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Typography variant="subtitle1">
                                {row.deposit.slice(0, 20)}
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItmes: "center" }}>
                                <Box sx={{ display: "block" }}>
                                    <Typography variant="subtitle1">
                                        {row.rewards.slice(0, 12)}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ fontWeight: "250" }}>
                                        {`$${modifyValueString(row.rewardValue)}`}
                                    </Typography>
                                </Box>
                                <Box
                                    component="img"
                                    sx={{
                                        height: "28px",
                                        marginLeft: "5px",
                                        alignSelf: "center"
                                    }}
                                    src={row.rewardLogoUrl}
                                />
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Typography variant="subtitle1">
                                {`$${modifyValueString(row.value)}`}
                            </Typography>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }
    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: theme.dark.palette.background.paper }}>
                        <TableCell size="small" sx={{ borderTop: 1, borderTopWidth: 1, borderTopColor: theme.dark.palette.background.default }}>
                            <Typography variant="h6">
                                {group}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell size="small">{headings[0]}</StyledTableCell>
                        {headings.slice(1).map((heading, index) => {
                            return <StyledTableCell key={index} size="small" align="right">{heading}</StyledTableCell>
                        })}
                    </TableRow>
                </TableHead>
                {isLoading
                    ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={6} sx={{ textAlign: "center", padding: 0, borderBottom: 0 }}>
                                <Paper sx={{ borderRadius: 0 }}>
                                    <Box display={"grid"} sx={{ width: "100%"}}>
                                    <Typography variant="body2">
                                        Loading...
                                    </Typography>
                                    <LinearProgress color="inherit" />
                                    </Box>
                                </Paper>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    : renderTableBody()
                }
            </Table>
        </Box>
    )
}

export default FxSwapFarmGroup;