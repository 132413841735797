import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from "react-redux";
import { store } from "./state/store";
import App from './App';
import { Web3ReactProvider, createWeb3ReactRoot } from "@web3-react/core";
import getLibrary from './utils/getLibrary';
import { NetworkContextName } from './constants/Misc';
import ApplicationUpdater from "./state/application/applicationUpdater";
import PriceUpdater from "./state/prices/pricesUpdater";

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <PriceUpdater />
    </>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary}>
      <Provider store={store}>
        <Updaters />
        <App />
      </Provider>
    </Web3ProviderNetwork>
  </Web3ReactProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
