import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Constants } from "../constants";

const NotFound: React.FC = () => {
    //The state will be reset when user is directed to this page
    //path = "*"
    const theme = useTheme();
    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate("/");
    }
    return (
        <Box
            sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Typography
                variant="h1"
                sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold"
                }}
            >
                {Constants.Errors.ERROR404}
            </Typography>
            <Typography
                variant="h2"
                sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold"
                }}
            >
                {Constants.Errors.PAGE_NOT_FOUND}
            </Typography>
            <Button
                onClick={handleRedirect}
                variant="contained"
                sx={{ mt: 2 }}
            >
                {Constants.Errors.BACK_TO_DASHBOARD}
            </Button>
        </Box>
    )
}

export default NotFound