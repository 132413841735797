import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { List, ListItemButton, ListSubheader } from "@mui/material";
import UserIcon from "@mui/icons-material/Person";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useAppSelector, useAppDispatch } from "../state/ReduxHooks"
import { selectUser, removeUser } from "../state/userSlice";
import { logout, selectAuth } from "../state/authSlice";

interface Props {
    isLoggedIn?: boolean;
    children?: React.ReactNode;
    showAddress?: boolean
}

const UserAvatar: React.FC<Props> = ({ isLoggedIn, showAddress, children}) => {
    const user = useAppSelector(selectUser);
    let walletAddress: string = ''
    if (user.walletAddress) {
        walletAddress = `${user.walletAddress.substring(0, 5)}...${user.walletAddress.substring(user.walletAddress.length - 5)}`
    }
    const loggedIn = useAppSelector(selectAuth)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const anchorRef = useRef(null);
    const [avatarOpen, setAvatarOpen] = useState(false);

    let showAddr = true;
    if (!showAddress) {
        showAddr = false
    }
    console.log(showAddr)

    const handleLogin = () => {
        navigate("/Login")
    }

    const handleLogout = () => {
        //reset state
        dispatch(removeUser())
        dispatch(logout());
        navigate("/");
    }

    const handleAvatarOpen = () => {
        setAvatarOpen(true);
    }

    const handleAvatarClose = () => {
        setAvatarOpen(false);
    }

    const render = () => {
        if (loggedIn.accessToken) {
            return (
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', textAlign: 'center' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            <Typography noWrap={true}>{walletAddress}</Typography>
                        </ListSubheader>
                    }
                >
                    <ListItemButton component="a" href="/profile">
                        <ListItemIcon>
                            <UserIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                    </ListItemButton>
                </List>
                // <List>
                //     <Typography variant="subtitle1" color={theme.palette.text.primary} sx={{ textAlign: "center" }}>
                //         {user.walletAddress}
                //     </Typography>
                //     <Divider />
                //     <Box sx={{ mt: 2 }}>
                //         {/* MenuItem component={Link} to="#" */}
                //         <MenuItem component={Link} to="/">
                //             <ListItemIcon>
                //                 <UserIcon fontSize="small" />
                //             </ListItemIcon>
                //             <ListItemText
                //                 primary={
                //                     <Typography variant="subtitle2" color={theme.palette.text.primary}>
                //                         {"Profile"}
                //                     </Typography>
                //                 }
                //             />
                //         </MenuItem>
                //         {/*set component to "a" and use href prop (instead of to prop) for external links*/}
                //         {/* <MenuItem component={Link} to="/private-note">
                //             <ListItemIcon>
                //                 <TextSnippetIcon fontSize="small" />
                //             </ListItemIcon>
                //             <ListItemText
                //                 primary={
                //                     <Typography variant="subtitle2" color={theme.palette.text.primary}>
                //                         {"Private Notes"}
                //                     </Typography>
                //                 }
                //             />
                //         </MenuItem> */}
                //     </Box>
                //     <ListItemButton component="a" href="#simple-list">
                //         <ListItemText primary="Logout" />
                //     </ListItemButton>
                //     <Box sx={{ p: 1 }}>
                //         <Button onClick={() => handleLogout()} color="primary" fullWidth variant="outlined">
                //             {"Logout"}
                //         </Button>
                //     </Box>
                // </List>
            );
        } else {
            return (
                <Box>
                    <Button onClick={() => handleLogin()} fullWidth>
                        <Typography variant="subtitle2" color={theme.palette.text.primary}>
                            {"Login"}
                        </Typography>
                    </Button>
                </Box>
            );
        }
    }
    return (
        <Box>
            <Button
                // component={ButtonBase}
                // onClick={handleAvatarOpen}
                // ref={anchorRef}
                to='/profile' component={RouterLink}
                sx={{
                    alignItems: "center",
                    display: "flex"
                }}
            >
                <Avatar
                    sx={{
                        height: 32,
                        width: 32,
                        backgroundColor: theme.palette.primary.main,
                        ...(showAddr ? { mr : 1 } : { mr : 0 })
                    }}
                    //src -> image url
                />
                { showAddr
                    ? <Typography
                        noWrap={true}
                        color={theme.palette.text.secondary}
                        sx={{ fontWeight: "medium" }}
                    >
                        {walletAddress && walletAddress}
                    </Typography>
                    : <></>
                }
            </Button>
            {/* <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom"
                }}
                keepMounted
                open={avatarOpen}
                onClose={handleAvatarClose}
                PaperProps={{
                    sx: { width: 200 }
                }}
            >
                <Box sx={{ p: 1.5 }}>
                    {render()}
                </Box>
            </Popover> */}
        </Box>
    )
}

export default UserAvatar