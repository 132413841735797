import { createListenerMiddleware } from "@reduxjs/toolkit";
import { removeUser, setUser } from "../userSlice";
import { clearLiquidityPositions } from "../tokens/tokensActions";
import { clearFarms } from "../liquidityFarms/farmActions";
import { RootState } from "../store";
import { getLiquidityPositions } from "../../utils/Liquidity";
import { updateLiquidityPositions } from "../tokens/tokensActions";
import { Farms } from "../liquidityFarms/farmReducer";
import { updateFarms } from "../liquidityFarms/farmActions";
import { getPendingReward, getUserInfo } from "../../utils/Farm";
import { Constants } from "../../constants";
import { getFxSwapFarmMetrics } from "../../utils/Metrics";
import { clearMetrics, updateFxswapFarmMetrics } from "../metrics/metricActions";
import { FxswapFarmMetric } from "state/metrics/metricsReducer";

export const userMiddleware = createListenerMiddleware();

//Reset user liquidity position on user/removeUser
userMiddleware.startListening({
    actionCreator: removeUser,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(clearMetrics());
        listenerApi.dispatch(clearLiquidityPositions());
        await listenerApi.delay(3000);
        listenerApi.dispatch(clearFarms());
    }
})

//Fetch user liquidity position on user/setUser
userMiddleware.startListening({
    actionCreator: setUser,
    effect: async (action, listenerApi) => {
        const fxswapFarmMetrics = await getFxSwapFarmMetrics();
        if (fxswapFarmMetrics && fxswapFarmMetrics.AllData) {
            listenerApi.dispatch(updateFxswapFarmMetrics(fxswapFarmMetrics))
        }

        const state = listenerApi.getState() as RootState;
        if (state.user.user.walletAddress != null) {
            //0x785f81e7bb9f97bc69ad9eb5b2d605244ae37003
            //0x20aea6ce875d85104a79c670a224287bbaadc5bc
            //0xd471498a32975dbb19be1a75a753cc7157be2f04
            //const testAddr = "0xc3415a17f026aca1e2c251509acdbf10d69f883a";
            const liquidityPositions = await getLiquidityPositions(state.user.user.walletAddress);
            if (liquidityPositions) {
                listenerApi.dispatch(updateLiquidityPositions(liquidityPositions.data));
            }

            let farms: Farms = Constants.Farms.FXFarms;
            const fxswapFarmMetric: FxswapFarmMetric | null = await getFxSwapFarmMetrics();
            const length = Object.keys(farms).length
            for (let i = 0; i < length; i++) {
                let [pendingRewards, userInfo] = await Promise.all([
                    getPendingReward(i, state.user.user.walletAddress),
                    getUserInfo(i, state.user.user.walletAddress)
                ]);
                let lpTokenValue: string = "0";
                if (fxswapFarmMetric) {
                    lpTokenValue = fxswapFarmMetric.AllData.lpTokenValue[i];
                }
                farms = {
                    ...farms,
                    [i]: {
                        ...farms[i],
                        liquidityDeposited: userInfo.amount,
                        unclaimedRewards: pendingRewards.toString(),
                        lpTokenValue: lpTokenValue,
                    }
                }
            }
            listenerApi.dispatch(updateFarms(farms));
        }
    }
})
