import React, {useState} from "react";
import {useTheme} from "@mui/material/styles";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from "@mui/icons-material/Menu";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import UserAvatar from "../components/UserAvatar";
import CustomButton from "../components/CustomButton";
import {Constants} from "../constants";
import ComputerIcon from "@mui/icons-material/Computer";

type Anchor = "left";

const Sidebar: React.FC = () => {
    const theme = useTheme();
    const [state, setState] = useState(
        {left: false}
    );

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key == "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }
        setState({...state, [anchor]: open});
    }

    const list = (anchor: Anchor) => (
        <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem disablePadding>
                    <UserAvatar showAddress={true}/>
                </ListItem>
                <ListItem sx={{ padding: 0.5, paddingTop: 2 }}>
                    <CustomButton link="/" external={false} icon={<DashboardRoundedIcon />} text={Constants.Layout.DASHBOARD} />
                </ListItem>
                <ListItem sx={{ padding: 0.5, paddingTop: 2 }}>
                    <CustomButton link="https://starscan.io/" external={true} icon={<ComputerIcon />} text={Constants.Layout.EXPLORER} />
                </ListItem>
            </List>
        </Box>
    )

    return (
        <div>
            {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button
                        color="inherit"
                        variant="outlined"
                        onClick={toggleDrawer(anchor, true)}
                        sx={{
                            borderRadius: 2,
                            minWidth: "auto",
                            padding: 1,
                            color: theme.palette.text.secondary,
                            //borderColor: alpha(theme.palette.text.secondary, 0.2),
                        }}>
                        <MenuIcon fontSize="small"/>
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    )
}

export default Sidebar;