import React from 'react';
import { useAppSelector } from '../state/ReduxHooks';
import { selectAuth } from "../state/authSlice";
import { Navigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { Loader } from './Loader';

interface Props {
    children?: React.ReactNode;
}

const AuthGuard: React.FC<Props> = ({ children }) => {
    const auth = useAppSelector(selectAuth);
    const location = useLocation();

    if (auth.accessToken === null) {
        return (
            <Navigate to={"/login"} state={{ from: location }} replace />
        )
    } else if (auth.accessToken === 'fetching') {
        return <Loader />
    } else {
        return <Box>{children}</Box>
    }
}

export default AuthGuard;