import { useEffect } from "react";
import { useAppDispatch } from "../ReduxHooks";
import useIsWindowVisible from "../../hooks/useIsWindowVisible";
import { updatePrices } from "./pricesActions";
import { getTokenPrices } from "../../utils/Token";
import { Constants } from "../../constants";

const currenciesArr: string[] = Object.keys(Constants.Tokens.SupportedTokenSymbols);

export default function Updater(): null {
    const dispatch = useAppDispatch();
    const windowVisible = useIsWindowVisible();

    const fetchPrices = async () => {
        const data = await getTokenPrices(currenciesArr)
        if (data) {
            dispatch(updatePrices(data));
        }
    }

    //fire an update to the prices state on-mount and periodically.
    useEffect(() => {
        //stops updating if windowVisible is false.
        if (!windowVisible) return

        //fires once when the updater mounts, and subsequently whenever the window becomes visible.
        fetchPrices();

        //When windowVisible is true, updates in 5 min intervals.
        //Interval resets when user leaves and returns to window.
        //Thus, fetchPrices() always fires once whenever window becomes visible
        const interval = setInterval(() => {
            fetchPrices()           
        }, 60000 * 5);
        return () => clearInterval(interval);
    }, [windowVisible]);

    return null
}