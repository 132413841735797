import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Collapse from "@mui/material/Collapse";
import DataTable from "../components/DataTable";
import CircularProgress from '@mui/material/CircularProgress';

interface AccordionTableProps {
    iconComponent: React.ReactNode,
    dataComponent: React.ReactNode | null,
    title: string,
    value: string | number,
    subtitle?: string,
    loading?: boolean,
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={1} square {...props} />
))(({ theme }) => ({
    border: `0px ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: "none",
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.5rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(2, 43, 100, 0)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: "row",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)",
    },
    height: 2
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px slid rgba(0, 0, 0, .125)",
}));

const AccordionTable: React.FC<AccordionTableProps> = ({ iconComponent, dataComponent, title, value, subtitle, loading }) => {

    const icon = iconComponent;
    const title_ = title;
    const value_ = value;
    var subtitle_: string;
    if (subtitle) {
        subtitle_ = subtitle
    }

    const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);

    const handleExpand = () => {
        setAccordionExpanded(!accordionExpanded)
    }

    return (
        <Box>
            <Paper elevation={2} sx={{ borderRadius: 0 }}>
                <Box sx={{
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "16px",
                    paddingRight: "16px"
                }}>
                    {icon}
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginLeft: "10px",
                        alignItems: "center",
                        flex: "1 1 0"
                    }}>
                        <Typography variant="h4">
                            {title_}
                        </Typography>
                        {loading
                            ? <CircularProgress color="inherit" size="1.5rem" />
                            : <Typography variant="h4">
                                {value_}
                            </Typography>
                        }
                    </Box>
                    <IconButton size="small" onClick={handleExpand}
                    >
                        <ExpandMoreIcon sx={{
                            transform: accordionExpanded ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "all 100ms ease 0s",
                        }}
                        />
                    </IconButton>
                </Box>
            </Paper>
            <Collapse in={accordionExpanded}>
                <DataTable dataComponent={dataComponent} />
            </Collapse>
        </Box>
    )
}

export default AccordionTable;