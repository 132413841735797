import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Constants } from "../constants";
import { useAppDispatch } from "../state/ReduxHooks";
import serveraxios from "../config/ServerAxios";
import { LoginSuccess } from "../state/authSlice";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isAddress } from "ethers/lib/utils";

export interface RegisterData {
    name: string;
    email: string;
    walletAddress: string;
    password: string;
    status: boolean
}

const validationSchema = yup
    .object({
        name: yup.string().max(20, Constants.Create.NAME_VALID).required(Constants.Create.NAME_REQUIRED),
        email: yup.string().email(Constants.Create.EMAIL_VALID).required(Constants.Create.EMAIL_REQUIRED),
        walletAddress: yup.string().test("validAddress", Constants.Create.WALLET_VALID, function(value) {
            const validAddress = isAddress(value!);
            const zeroX = value ? value!.slice(0, 2) : null;
            let valid = false;
            if (validAddress && zeroX === "0x") {
                valid = true;
            }
            return valid
        }).required(Constants.Create.WALLET_REQUIRED),
        password: yup.string().min(8, Constants.Create.PASSWORD_VALID).required(Constants.Create.PASSWORD_REQUIRED),
        status: yup.boolean().notRequired(),
    }).required();

const Create: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const [checked, setChecked] = useState<boolean>(false);
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<RegisterData>({
        defaultValues: {
            name: "",
            email: "",
            password: "",
            walletAddress: "",
            status: false,
        },
        resolver: yupResolver(validationSchema)
    });

    const handleChecked = (event: any) => {
        console.log(event.target.checked);
        setChecked(event.target.checked);
    }

    //event: React.FormEvent<HTMLFormElement>
    const onSubmit: SubmitHandler<RegisterData> = async (inputData: RegisterData) => {
        if (checked) {
            console.log("Can submit");
            try {
                const { data: resData } = await serveraxios.post<LoginSuccess>(
                    "api/register",
                    {
                        name: inputData.name,
                        email: inputData.email,
                        walletAddress: inputData.walletAddress.toLowerCase(),
                        password: inputData.password,
                        status: inputData.status
                    }
                )
                console.log(resData);
                navigate("/login");
            }
            catch (error: any) {
                console.log(error.message);
            }
        } else {
            console.log("Cannot submit");
            alert(Constants.Create.ACCEPT_TERMS_PRIVACY);
        }
    }

    return (
        <Box
            sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
            <Typography
                variant="h2"
                sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold"
                }}
            >
                {Constants.Create.REGISTER_HEADER}
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    color: theme.palette.text.secondary
                }}
            >
                {Constants.Create.REGISTER_SUBHEADER}
            </Typography>
            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    mt: 2,
                    display: "block",
                    width: "75%",
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: theme.palette.text.secondary
                            }}
                        >
                            {Constants.Create.NAME_HEADER}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (<TextField
                                {...field}
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Required"
                                variant="filled"
                                size="small"
                                sx={{
                                    marginTop: "0px"
                                }}
                            />
                            )}
                        />
                        {errors.name && <Typography sx={{ color: "#bf1650", display: "inline" }}>{errors.name.message}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: theme.palette.text.secondary
                            }}
                        >
                            {Constants.Create.EMAIL_HEADER}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (<TextField
                                {...field}
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Required"
                                variant="filled"
                                size="small"
                                sx={{
                                    marginTop: "0px"
                                }}
                            />
                            )}
                        />
                        {errors.email && <Typography sx={{ color: "#bf1650", display: "inline" }}>{errors.email.message}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: theme.palette.text.secondary
                            }}
                        >
                            {Constants.Create.WALLET_HEADER}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="walletAddress"
                            control={control}
                            render={({ field }) => (<TextField
                                {...field}
                                margin="normal"
                                required
                                fullWidth
                                id="wallet"
                                label="Required"
                                variant="filled"
                                size="small"
                                sx={{
                                    marginTop: "0px"
                                }}
                            />
                            )}
                        />
                        {errors.walletAddress && <Typography sx={{ color: "#bf1650", display: "inline" }}>{errors.walletAddress.message}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: theme.palette.text.secondary
                            }}
                        >
                            {Constants.Create.PASSWORD_HEADER}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (<TextField
                                {...field}
                                type="password"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label="Required"
                                variant="filled"
                                size="small"
                                sx={{
                                    marginTop: "0px"
                                }}
                            />
                            )}
                        />
                        <Box>
                            {errors.password && <Typography sx={{ color: "#bf1650", display: "inline" }}>{errors.password.message}</Typography>}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                value="agreeTermsAndConditions"
                                color="primary"
                                checked={checked}
                                onChange={handleChecked} />}
                            label={
                                <div>
                                    <span>{"I accept the"} </span>
                                    <a href="https://pundix.com/privacy/" style={{ color: "#2196f3" }}>Terms of Use</a>
                                    <span>{" and "}</span>
                                    <a href="https://pundix.com/privacy/" style={{ color: "#2196f3" }}>Privacy Policy</a>
                                    <span>{"."}</span>
                                </div>
                            }
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{ mt: 2, mb: 2 }}
                >
                    {Constants.Create.CREATE_ACCOUNT}
                </Button>
            </Box>
        </Box >
    )
}

export default Create;

