import { useCallback, useEffect, useState } from "react";
import { useActiveWeb3React } from "../../hooks/web3";
import { updateBlockNumber } from "./applicationActions";
import { useAppDispatch } from "../ReduxHooks";
import useIsWindowVisible from "../../hooks/useIsWindowVisible";
import useDebounce from "../../hooks/useDebounce";

export default function Updater(): null {
    const { library, chainId } = useActiveWeb3React();
    const dispatch = useAppDispatch();

    const windowVisible = useIsWindowVisible();

    const [state, setState] = useState<{ chainId: number | undefined; blockNumber: number }>({
        chainId,
        blockNumber: 0,
    })

    const [marker, setMarker] = useState<number>(0);

    const blockNumberCallback = useCallback((blockNumber: number) => {
        setState((state) => {
            if (chainId === state.chainId) {
                if (typeof state.blockNumber !== "number") {
                    return {
                        chainId,
                        blockNumber
                    }
                }
                return {
                    chainId,
                    blockNumber: Math.max(blockNumber, state.blockNumber)
                }
            }
            return state;
        })
    }, [chainId, setState]
    )

    useEffect(() => {
        if (!library || !chainId || !windowVisible) return undefined
        console.log(chainId)
        console.log(library);
        setState({ chainId, blockNumber: 0 })

        library
            .getBlockNumber()
            .then(blockNumberCallback)
            .catch((error) => console.error(`Failed to get block number for chainId: ${chainId}`, error))

        library.on('block', blockNumberCallback)
        return () => {
            library.removeListener('block', blockNumberCallback)
        }
    }, [dispatch, chainId, library, blockNumberCallback, windowVisible])

    const debouncedState = useDebounce(state, 100);

    useEffect(() => {
        if (debouncedState.blockNumber - marker > 5) {
            setMarker(debouncedState.blockNumber);
        }
    }, [debouncedState.blockNumber, marker])

    useEffect(() => {
        if (!debouncedState.chainId || !debouncedState.blockNumber || !windowVisible) return
        dispatch(updateBlockNumber({ chainId: debouncedState.chainId, blockNumber: marker }))
    }, [windowVisible, dispatch, marker, debouncedState.chainId])

    return null;
}