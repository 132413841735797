import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from "@mui/material/TableBody";
import { StyledTableRow } from "../DataTable";
import { StyledTableCell } from "../DataTable";
import TableCell from "@mui/material/TableCell"
import { theme } from "../../theme";
import LPIcon from "../LPIcon";
import LPComposition from "../LPComposition";
import { PoolGroup } from "./PortfolioData";
import { Link, Paper } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useAppSelector } from "state/ReduxHooks";
import { getLiquidityAdditions, getLiquidityBurns } from "utils/Liquidity";
import { calcHistoricalLiquidityValue, calcUnrealizedGL1 } from "utils/FxSwap";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Web3 from "web3";
import { getFxEvmEvents } from "utils/evm";

const formulaText = "The unrealized is calculated by taking the current net value (sum of \
    liquidity and farm position, based on current price of FX) minus the historical net value (sum of \
    adding and removing liquidity to the pool, based on the price of FX at the time of each event), \
    represented as a precentage of the historical net value"

const FxSwapPoolGroup: React.FC<PoolGroup> = ({ group, headings, data }) => {
    const addr = useAppSelector((state) => state.user.user.walletAddress)
    const [isLoading, setIsLoading] = useState(true);
    const [isCalculating, setIsCalculating] = useState(true);
    const [additions, setAdditions] = useState<LiquidityAdds | null>(null);
    const [burns, setBurns] = useState<LiquidityBurns | null>(null);
    //getFxEvmEvents("0x785f81E7Bb9f97Bc69AD9eB5B2d605244aE37003","0x4bd522b2E25f6b1A874C78518EF25f5914C522dC")
    //getFxEvmEvents("0x785f81E7Bb9f97Bc69AD9eB5B2d605244aE37004","0x4bd522b2E25f6b1A874C78518EF25f5914C522dC")
    //const web3 = new Web3("wss://fx-json-web3.functionx.io:8546")
    //web3.eth.getTransactionReceipt("0xbbe09eae00d9253a879fb3552cd47e43ef247039f196f6db3431ab04f8756f03").then((res) => { console.log(res)})
    // web3.eth.getBalance("0x785f81E7Bb9f97Bc69AD9eB5B2d605244aE37003").then((res) => console.log(res));
    // web3.eth.getPastLogs({
    //     fromBlock: "9227575",
    //     toBlock:"9227575",
    //     address: "0x7eD74ebDA2f2AD577d9ef2aA6b6573b15FC14E39",
    //     // topics: ["0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef", ]
    // }).then((res) => {
    //     console.log(res)
    //     console.log(BigInt(res[0].data).toString())
    //     console.log(parseInt(res[0].data, 16))
    // })

    const modifyValueString = (str: string) => {
        const indexOfDot = str.indexOf(".")
        return str.slice(0, indexOfDot + 6)
    }

    useEffect(() => {
        let isSubscribed = true;
        const fetchGraphQL = async () => {
            if (addr) {
                const a = await getLiquidityAdditions(addr);
                const b = await getLiquidityBurns(addr);
                if (isSubscribed) {
                    setAdditions(a);
                    setBurns(b);
                }
            }
        }
        fetchGraphQL().catch();
        return () => {
            isSubscribed = false
        }
    }, [addr, data]);

    const dataUpdated = useMemo(() => {
        if (additions && burns) {
            return calcUnrealizedGL1(calcHistoricalLiquidityValue(data, additions, burns));
        } else {
            return data
        }
    }, [data, additions, burns])

    useEffect(() => {
        const interval = setTimeout(() => setIsLoading(false), 15000);
        return () => clearTimeout(interval);
    }, []);

    useEffect(() => {
        //console.log(dataUpdated)
        if (dataUpdated.length >= 1 && dataUpdated[0].farmTotalValue !== "0") {
            setIsCalculating(false);
        } else {
            setIsCalculating(true);
        }
    }, [dataUpdated])

    const renderTableBody = () => {
        if (dataUpdated.length === 0 || !dataUpdated[0].id) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={6} sx={{ textAlign: "center", padding: 0, borderBottom: 0 }}>
                            <Paper sx={{ borderRadius: 0 }}>
                                <Typography variant="body2">
                                    {"----You do not have any ongoing positions - head to "}
                                    <Link href="https://fx-swap.io/#/pool">Fx Swap</Link>
                                    {" to get started----"}
                                </Typography>
                            </Paper>
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }
        return (
            <TableBody>
                {dataUpdated.map((row, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <LPIcon
                                    token0Src={row.token0LogoUrl}
                                    token1Src={row.token1LogoUrl}
                                />
                                <Box sx={{ display: "block" }}>
                                    <Box>
                                        <Typography variant="subtitle1">
                                            {row.position}
                                        </Typography>
                                    </Box>
                                    <Typography variant="subtitle2" sx={{ fontWeight: "250" }}>
                                        {`${row.positionAddress.slice(0, 12)}...`}
                                    </Typography>
                                </Box>
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Box sx={{ display: "block" }}>
                                <Typography variant="subtitle1">
                                    {row.liquidityTokenBalance.slice(0, 12)}
                                </Typography>
                                <LPComposition
                                    token0PooledAmount={row.token0PooledAmount.slice(0, 16)}
                                    token0Src={row.token0LogoUrl}
                                    token1PooledAmount={row.token1PooledAmount.slice(0, 16)}
                                    token1Src={row.token1LogoUrl}
                                />
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Typography variant="subtitle1">
                                {`$${modifyValueString(row.liquidityValue)}`}
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Box sx={{ display: "block" }}>
                                {isCalculating
                                    ? <CircularProgress color="inherit" size="1rem" />
                                    : <Box>
                                        <Typography variant="subtitle1" sx={{ color: row.unrealizedGL_P.charAt(0) === "-" ? "red" : "green"}}>
                                            {row.unrealizedGL_P.charAt(0) === "-" ? `${row.unrealizedGL_P.slice(0, 8)}%` : `${row.unrealizedGL_P.slice(0, 7)}%`}
                                        </Typography>
                                        <Typography variant="subtitle2" sx={{ color: row.unrealizedGL_P.charAt(0) === "-" ? "red" : "green", fontWeight: "250" }}>
                                            {row.unrealizedGL_V.charAt(0) === "-" ? `$${row.unrealizedGL_V.slice(0, 8)}` : `$${row.unrealizedGL_V.slice(0, 7)}`}
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }
    
    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: theme.dark.palette.background.paper }}>
                        <TableCell size="small" sx={{ borderTop: 1, borderTopWidth: 1, borderTopColor: theme.dark.palette.background.default }}>
                            <Typography variant="h6">
                                {group}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell size="small">{headings[0]}</StyledTableCell>
                        {headings.slice(1, -1).map((heading, index) => {
                            return <StyledTableCell key={index} size="small" align="right">{heading}</StyledTableCell>
                        })}
                        <StyledTableCell size="small" align="right">
                            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {headings[headings.length - 1]}
                                <Tooltip title={formulaText} placement="top-end">
                                    <InfoIcon fontSize="small" sx={{ paddingLeft: 0.5 }} />
                                </Tooltip>
                            </Box>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                {isLoading
                    ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={6} sx={{ textAlign: "center", padding: 0, borderBottom: 0 }}>
                                <Paper sx={{ borderRadius: 0 }}>
                                    <Box display={"grid"} sx={{ width: "100%" }}>
                                        <Typography variant="body2">
                                            Loading...
                                        </Typography>
                                        <LinearProgress color="inherit" />
                                    </Box>
                                </Paper>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    : renderTableBody()
                }
            </Table>

        </Box>
    )
}
export default FxSwapPoolGroup;