import { AbstractConnector } from "@web3-react/abstract-connector";
import INJECTED_ICON_URL from "../assets/images/arrow-right.svg";
import METAMASK_ICON_URL from "../assets/images/metamask.png";
import WALLETCONNECT_ICON_URL from "../assets/images/walletConnectIcon.svg";
import { injected, walletConnect } from "../connectors";

export interface WalletInfo {
    connector?: AbstractConnector;
    name: string;
    iconURL: string;
    description: string;
    href: string | null;
    color: string;
    primary?: true;
    mobile?: true;
    mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
    INJECTED: {
        connector: injected,
        name: "Injected",
        iconURL: INJECTED_ICON_URL,
        description: "Injected web3 provider",
        href: null,
        color: "#010101",
        primary: true,
    },
    METAMASK: {
        connector: injected,
        name: "MetaMask",
        iconURL: METAMASK_ICON_URL,
        description: "MetaMask browser extension",
        href: null,
        color: "#E8831D",
    },
    WALLET_CONNECT: {
        connector: walletConnect,
        name: "WalletConnect",
        iconURL: WALLETCONNECT_ICON_URL,
        description: "Connect to a myriad of Wallet apps",
        href: null,
        color: "#4196FC",
        mobile: true,
    }
}