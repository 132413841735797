import {Contract, ContractInterface, ethers} from "ethers";
import {Constants} from "../constants";

import FXSwapV2FactoryAbi from "../abis/FXSwapV2Factory.json";
import FXSwapV2Router02Abi from "../abis/FXSwapV2Router02.json";
import FXSwapV2PairAbi from "../abis/FXSwapV2Pair.json";
import FXSwapV2ERC20Abi from "../abis/FXSwapV2ERC20.json";
import ERC20Abi from "../abis/ERC20.json";
import MULTICALLAbi from "../abis/Multicall.json";
import ERC1967PROXYAbi from "../abis/ERC1967Proxy.json";
import MasterChefV2Abi from "../abis/MasterChefV2.json";

import {
    ERC1967Proxy,
    ERC20,
    FXSwapV2ERC20,
    FXSwapV2Factory,
    FXSwapV2Pair,
    FXSwapV2Router02,
    MasterChefV2,
    Multicall,
} from "../abis/types";

const fxCoreProviderUrl = Constants.Endpoint.FXCORE_ENDPOINT;

//Non react hook version of useContract.ts
function getContract<T extends Contract = Contract>(
    providerUrl: string,
    contractAddress: string,
    abi: ContractInterface,
): T | null {
    const provider = new ethers.providers.JsonRpcProvider(providerUrl);
    try {
        const contract: Contract = new ethers.Contract(contractAddress, abi, provider)
        return contract as T;
    } catch (error) {
        console.error("Failed to get contract", error);
        return null;
    }
}

export function getFXSwapV2FactoryContract() {
    return getContract<FXSwapV2Factory>(fxCoreProviderUrl, Constants.Address.FXSWAPV2FACTORY_ADDRESS, FXSwapV2FactoryAbi);
}

export function getFXSwapV2RouterContract() {
    return getContract<FXSwapV2Router02>(fxCoreProviderUrl, Constants.Address.FXSWAPV2ROUTER02_ADDRESS, FXSwapV2Router02Abi);
}

export function getFXSwapV2PairContract(tokenAddress: string) {
    return getContract<FXSwapV2Pair>(fxCoreProviderUrl, tokenAddress, FXSwapV2PairAbi);
}

export function getFXSwapV2Erc20Contract(tokenAddress: string) {
    return getContract<FXSwapV2ERC20>(fxCoreProviderUrl, tokenAddress, FXSwapV2ERC20Abi);
}

export function getErc20Contract(tokenAddress: string) {
    return getContract<ERC20>(fxCoreProviderUrl, tokenAddress, ERC20Abi);
}

export function getMulticallContract() {
    return getContract<Multicall>(fxCoreProviderUrl, Constants.Address.MULTICALL_ADDRESS, MULTICALLAbi);
}

//need to get ABI and type (this will be for the farm)
export function getErc1967Proxy(){
    return getContract<ERC1967Proxy>(fxCoreProviderUrl, Constants.Address.ERC1967PROXY_ADDRESS, ERC1967PROXYAbi);
}

//to use a proxy contract, use proxy address, and proxied contract abi
export function getMasterChefV2Contract() {
    return getContract<MasterChefV2>(fxCoreProviderUrl, Constants.Address.ERC1967PROXY_ADDRESS, MasterChefV2Abi);
}