import { createListenerMiddleware } from "@reduxjs/toolkit";
import { getPendingReward, getUserInfo } from "../../utils/Farm";
import { getLPTokens, getLiquidityPositions } from "../../utils/Liquidity";
import { updateBlockNumber } from "../application/applicationActions";
import { RootState } from "../store";
import { updateAllLpTokens, updateLiquidityPositions } from "../tokens/tokensActions";
import { Farms } from "../liquidityFarms/farmReducer";
import { updateFarms } from "../liquidityFarms/farmActions";
import { Constants } from "../../constants";
import { getFxSwapFarmMetrics } from "../../utils/Metrics";
import { updateFxswapFarmMetrics } from "../metrics/metricActions";

export const blockNumberMiddleware = createListenerMiddleware();

//Fires updates whenever new block number
blockNumberMiddleware.startListening({
    actionCreator: updateBlockNumber,
    effect: async (action, listenerApi) => {
        const fxswapFarmMetrics = await getFxSwapFarmMetrics();
        if (fxswapFarmMetrics && fxswapFarmMetrics.AllData) {
            listenerApi.dispatch(updateFxswapFarmMetrics(fxswapFarmMetrics))
        }
        const lpTokens = await getLPTokens();
        if (lpTokens) {
            listenerApi.dispatch(updateAllLpTokens(lpTokens.data))
            const state = listenerApi.getState() as RootState;
            if (state.user.user.walletAddress != null) {
                //const testAddr = "0x93975e1e841079541c654d8841db9a7d2d17ac58";
                const liquidityPositions = await getLiquidityPositions(state.user.user.walletAddress);
                if (liquidityPositions) {
                    listenerApi.dispatch(updateLiquidityPositions(liquidityPositions.data));
                }

                let farms: Farms = { ...Constants.Farms.FXFarms }
                const length = Object.keys(farms).length
                //get user rewards and info for each pool
                let allRewardsAndInfoPromises: [Promise<number>, Promise<{ amount: string; rewardDebt: string; }>][] = [];
                for (let i = 0; i < length; i++) {
                    allRewardsAndInfoPromises.push([getPendingReward(i, state.user.user.walletAddress), getUserInfo(i, state.user.user.walletAddress)])
                }
                let allRewardsAndInfo: [number, { amount: string; rewardDebt: string; }][] = await Promise.all(
                    allRewardsAndInfoPromises.map(array => Promise.all(array))
                )

                //store the liquidity position of user in 'farm' state
                for (let i = 0; i < length; i++) {
                    let lpTokenValue: string = "0";
                    if (fxswapFarmMetrics) {
                        lpTokenValue = fxswapFarmMetrics.AllData.lpTokenValue[i];
                    }

                    farms[i] = {
                        ...farms[i],
                        liquidityDeposited: allRewardsAndInfo[i][1].amount,
                        unclaimedRewards: allRewardsAndInfo[i][0].toString(),
                        lpTokenValue,
                    }
                }
                
                if ((listenerApi.getState() as RootState).user.user.walletAddress != null
                    && (listenerApi.getState() as RootState).auth.auth.success != false) {
                    listenerApi.dispatch(updateFarms(farms))
                }
            }
        }
    },
})