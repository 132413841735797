import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import InsightsIcon from '@mui/icons-material/Insights';
import UniswapV3Card from "./UniswapV3Card";
import FxSwapCard from "./FxSwapCard";
import { Constants } from "../constants";
import { getUniswapV3FxEthMetrics, getUniswapV3FxUsdtMetrics } from "../utils/Metrics";

const DefiSnapshot: React.FC = () => {
    const [uniswapv3Pools, setUniswapv3Pools] = useState(Constants.UniswapV3.UniswapV3FXData);
    const fxswapFarmsSnapshot = Constants.FxFarmsSnapshot.FXFarms;
    const [fxswapTvl, setFxswapTvl] = useState(0);

    const calculateFxswapTvl = (tvlArr: number[]) => {
        setFxswapTvl(tvlArr.reduce((acc, i): number => acc + i));
    }

    useEffect(() => {
        const fetchUniswapData = async () => {
            const pool1Data = await getUniswapV3FxEthMetrics();
            if (pool1Data) {
                setUniswapv3Pools((state) => ({
                    ...state,
                    pool1: {
                        ...state.pool1,
                        tvl: pool1Data.data.pool.totalValueLockedUSD,
                        token0Bonded: pool1Data.data.pool.totalValueLockedToken0,
                        token1Bonded: pool1Data.data.pool.totalValueLockedToken1,
                        txCount: pool1Data.data.pool.txCount,
                    }

                }))
            }
            const pool2Data = await getUniswapV3FxUsdtMetrics();
            if (pool2Data) {
                setUniswapv3Pools((state) => ({
                    ...state,
                    pool2: {
                        ...state.pool2,
                        tvl: pool2Data.data.pool.totalValueLockedUSD,
                        token0Bonded: pool2Data.data.pool.totalValueLockedToken0,
                        token1Bonded: pool2Data.data.pool.totalValueLockedToken1,
                        txCount: pool2Data.data.pool.txCount,
                    }

                }))
            }
        }
        fetchUniswapData();
        const interval = setInterval(() => {
            fetchUniswapData();
        }, 60000);
        return () => clearInterval(interval);
    }, []);
    
    return (
        <Box>
            <Paper elevation={2} sx={{ borderRadius: 0 }}>
                <Box sx={{
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "16px",
                    paddingRight: "16px"
                }}>
                    <InsightsIcon />
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginLeft: "10px",
                        alignItems: "center",
                        flex: "1 1 0"
                    }}>
                        <Typography variant="h4">
                            DeFi Snapshot
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    width: "100%",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingBottom: "16px",
                }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Uniswap V3
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <UniswapV3Card
                                token0={uniswapv3Pools.pool1.token0}
                                token0Bonded={uniswapv3Pools.pool1.token0Bonded}
                                token1={uniswapv3Pools.pool1.token1}
                                token1Bonded={uniswapv3Pools.pool1.token1Bonded}
                                tvl={uniswapv3Pools.pool1.tvl}
                                txCount={uniswapv3Pools.pool1.txCount}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <UniswapV3Card
                                token0={uniswapv3Pools.pool2.token0}
                                token0Bonded={uniswapv3Pools.pool2.token0Bonded}
                                token1={uniswapv3Pools.pool2.token1}
                                token1Bonded={uniswapv3Pools.pool2.token1Bonded}
                                tvl={uniswapv3Pools.pool2.tvl}
                                txCount={uniswapv3Pools.pool2.txCount}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="h5">
                                    Fx-Swap
                                </Typography>
                                <Typography variant="h5">
                                    {`TVL: ~ $${fxswapTvl.toLocaleString("en-US", {maximumFractionDigits: 2})}`}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <FxSwapCard snapshot={fxswapFarmsSnapshot} calculateTvl={calculateFxswapTvl} />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="h5">
                                    MarginX
                                </Typography>
                                <Typography variant="h5">
                                    {`TVL: ~`}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <MarginXCard/>
                        </Grid> */}
                    </Grid>
                </Box>
            </Paper>

        </Box>
    )
}
export default DefiSnapshot;