import React, { useState } from 'react'
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useTheme } from "@mui/material/styles";
import Header from './Header';

interface Props {
    children?: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
    const theme = useTheme();
    //Checks width of screen - for responsiveness.
    //True if max width for device 
    const isLg = useMediaQuery(theme.breakpoints.up("lg"), { defaultMatches: true });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
        console.log("handleSideBarOpen");
    }

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    }

    return (
        <Box
            id="page-top"
            sx={{
                backgroundColor: theme.palette.background.default,
                height: "100%",
                paddingTop: "60px",
            }}
        >
            <Header onSideBarOpen={handleSidebarOpen}/>
            <Box>{children}</Box>
        </Box>
    )
}

export default Layout;