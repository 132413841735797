import React, { useState } from 'react'
import axios, { AxiosResponse } from 'axios';

interface Delegations {
    new: boolean
    height: number
    delegatedValidators: DelegatedValidators
}

interface DelegatedValidators {
    [index: string]: DelegationDataPerValidator;
}

interface DelegationDataPerValidator {
    amount: string
    averagePrice: string
}

/**
 * check if user has migrated,if no return null, if yes return oldAddress
 * @param {string} ethAddress - user current ethereum hex address
 * @returns {Promise} cosmosAddress - user migrated old cosmos address
 */
const getCosmosAddress = async (ethAddress: string): Promise<string> => {
    const res: AxiosResponse<EthToCosmosAddress> = await axios.get(`https://fx-rest.functionx.io/ethermint/evm/v1/cosmos_account/${ethAddress}`)
    const cosmosAddress = res.data.cosmos_address
    return cosmosAddress
}

/**
 * Get delegate transactions data 
 * @param {string} sender - user cosmos address
 * @param {number} offset - nos of txs to be offset (must be greater than pagination_limit to flip the page)
 * @param {number} pagination_limit - maximum number of results return
 * @param {enum} order - order of transactions based on block heights
 * @returns {Promise} FxTransactionsData - Delegate transcations data (Descending order of heights)
 */
const getDelegateTxs = async (sender: string, offset: number, pagination_limit: number, order: "UNSPECIFIED" | "ASC" | "DESC" = "UNSPECIFIED"): Promise<FxTransactionsData> => {
    const res = await axios.get(`https://fx-rest.functionx.io/cosmos/tx/v1beta1/txs?events=message.sender%3D'${sender}'&events=message.module%3D'staking'&events=message.action%3D'%2Fcosmos.staking.v1beta1.MsgDelegate'&pagination.offset=${offset}&pagination.limit=${pagination_limit}&order_by=ORDER_BY_${order}`)
    const data: FxTransactionsData = res.data
    return data
}

// [ "/cosmos.staking.v1beta1.MsgDelegate"，“/cosmos.staking.v1beta1.MsgBeginRedelegate, "/cosmos.staking.v1beta1.MsgUndelegate"，"delegate", "begin_redelegate", "begin_unbonding" ]

const Test = () => {
    const [transaction, setTransaction] = useState<any | null>(null)

    // const test = async () => {
    //     // check if calculate has been made on this address
    //     if (delegations.new) {
    //         // check if user migrate before
    //         const oldCosmosAddress = await getOldCosmosAddress(userCosmosAddress)
    //         console.log('oldCosmosAddress', oldCosmosAddress)
    //         // if user migrated, check transactions in old address first
    //         if (oldCosmosAddress) {
    //             const res = await calculateAveragePriceForNew(oldCosmosAddress, delegations)
    //             if(res) delegations = res
    //         }
    //         const res = await calculateAveragePriceForNew(userCosmosAddress, delegations)
    //         if(res) delegations = res
    //         delegations.new = false
    //     } else {
    //         const res = await calculateAveragePrice(userCosmosAddress, delegations)
    //         if(res) delegations = res
    //     }

    //     console.log(delegations)
    // }

    return (
        <div>
            {/* <Button onClick={test}>Fetch</Button> */}
            <div>
                {/* <Typography>
          {transaction && transaction}
        </Typography> */}
            </div>
        </div>
    )
}

export default Test