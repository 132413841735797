import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "state/ReduxHooks";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from "@mui/material/TableBody";
import {StyledTableCell, StyledTableRow} from "./DataTable";
import TableCell from "@mui/material/TableCell";
import {Paper} from "@mui/material";
import {theme} from "../theme";
import LinearProgress from "@mui/material/LinearProgress";
import {
    calculateAveragePrice,
    calculateAveragePriceForNew,
    calculateTotalWithdrawals,
    DelegationsAveragePriceInfo,
    getCosmosAddress,
    getDelegatorDelegations,
    getOldCosmosAddress,
    getReward,
    getValidatorMoniker,
    getWithdrawals1
} from "utils/Delegations";
import {Constants} from "../constants";
import Big from "big.js";
import {editUserInfo, selectUser} from 'state/userSlice'

export interface DelegationsGroup {
    setDelTotal: React.Dispatch<React.SetStateAction<number>>
}

interface Delegations {
    [index: string]: {
        amount?: string,
        moniker?: string,
        reward?: string,
        rewardValue?: string,
        value?: string,
        gainOrLoss?: string
    }
}

// const delegationData = {
//     group: "Delegations",
//     headings: ["Validator", "Delegation", "Rewards", "Value", "Unrealised +/-"],
//     subHeadings: ["Total Rewards Withdrawn", "Total Delegation Rewards"],
// }

const Delegations: React.FC<DelegationsGroup> = ({ setDelTotal }) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser);
    const addr = user.walletAddress
    const blockNumber = useAppSelector((state) => state.application.blockNumber);

    const [delegationState, setDelegationState] = useState<Delegations | null>(null)
    const [totalDelegationRewards, setTotalDelegationRewards] = useState<string | 0>(0)
    const [withdrawnReward, setWithdrawnReward] = useState<string | 0>(0)
    const [fetching, setFetching] = useState<boolean>(false)
    const [value, setValue] = useState(0)

    const [isLoading0, setIsLoading0] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);

    const prices = useAppSelector((state) => state.prices.tokenPrices);
    let fxPrice = 0;
    if (prices) {
        const priceObj = prices.find(price => price.symbol === "FX")
        if (priceObj) {
            fxPrice = priceObj.priceUSD;
        }
    }

    const delegationTableData = {
        group: "Delegations",
        headings: ["Validator", "Delegation", "Rewards", "Value", "Unrealized P&L"],
        subHeadings: ["Total Rewards Withdrawn", "Total Delegation Rewards"],
    }

    let delegationsAveragePriceInfo: DelegationsAveragePriceInfo
    if (user.delegationsAveragePriceInfo) {
        delegationsAveragePriceInfo = JSON.parse(JSON.stringify(user.delegationsAveragePriceInfo))
    } else {
        delegationsAveragePriceInfo = {
            "new": true,
            "delegatedValidators": {},
            "height": 0
        }
    }

    const fetchDelegationInfo = async (addr: string) => {
        setFetching(true)
        const cosmosAddress = await getCosmosAddress(addr!)
        if (!cosmosAddress) {
            setIsLoading0(false);
            setIsLoading1(false);
            setFetching(false);
            return
        }
        const responses = await Promise.all([
            getDelegatorDelegations(cosmosAddress),
            getWithdrawals1(addr!, "staking", false),
        ])
        console.log(responses);
        
        const delegationsResponse = responses[0]
        const delegations: Delegations = {}
        let rewardSum = Big(0)
        let totalValue = Big(0)
        if (delegationsResponse && delegationsResponse.delegation_responses.length !== 0) {
            const rewardsPromises = []
            const validatorMonikersPromises = []
            for (let i = 0; i < delegationsResponse.delegation_responses.length; i++) {
                const validatorAddress = delegationsResponse.delegation_responses[i].delegation.validator_address
                rewardsPromises.push(getReward(cosmosAddress, validatorAddress))
                validatorMonikersPromises.push(getValidatorMoniker(validatorAddress))
            }
            const validatorMonikers = await Promise.all(validatorMonikersPromises)
            const rewards = await Promise.all(rewardsPromises)
            for (let i = 0; i < delegationsResponse.delegation_responses.length; i++) {
                const validatorAddress = delegationsResponse.delegation_responses[i].delegation.validator_address
                const reward = rewards[i] as string
                const amount = Big(delegationsResponse.delegation_responses[i].delegation.shares).div(Big("1e+18"))
                const value = Big(reward).add(Big(amount)).mul(fxPrice)
                const rewardValue = Big(reward).mul(fxPrice).toString()
                delegations[validatorAddress] = {
                    amount: amount.toString(),
                    moniker: validatorMonikers[i] as string,
                    reward: reward,
                    rewardValue: rewardValue,
                    value: value.toString()
                }
                totalValue = totalValue.add(value)
                rewardSum = rewardSum.add(Big(reward))
            }
            
            setTotalDelegationRewards(rewardSum.toString())
            setValue(totalValue.toNumber())

            //calculate gain/loss
            // check if calculate has been made on this address
            if (delegationsAveragePriceInfo.new) {
                // check if user migrate before
                const oldCosmosAddress = await getOldCosmosAddress(cosmosAddress)
                // if user migrated, check transactions in old address first
                if (oldCosmosAddress) {
                    const res = await calculateAveragePriceForNew(oldCosmosAddress, delegationsAveragePriceInfo)
                    if (res) delegationsAveragePriceInfo = res
                }
                const res = await calculateAveragePriceForNew(cosmosAddress, delegationsAveragePriceInfo)
                if (res) delegationsAveragePriceInfo = res
                delegationsAveragePriceInfo.new = false
            } else {
                const res = await calculateAveragePrice(cosmosAddress, delegationsAveragePriceInfo)
                if (res) {
                    delegationsAveragePriceInfo = res
                }
            }
            
            Object.keys(delegationsAveragePriceInfo.delegatedValidators).forEach(validator => {
                const calcAmount = Big(delegationsAveragePriceInfo.delegatedValidators[validator].amount).div(Big("1e+18"))
                if (calcAmount.toString() !== delegations[validator].amount) {
                    console.error(`discrepancy between amount from API and calculation, validator ${validator}`)
                    delegations[validator].gainOrLoss = Big(delegations[validator].value!).sub(calcAmount.mul(Big(delegationsAveragePriceInfo.delegatedValidators[validator].averagePrice))).toString()
                } else {
                    delegations[validator].gainOrLoss = Big(delegations[validator].value!).sub(calcAmount.mul(Big(delegationsAveragePriceInfo.delegatedValidators[validator].averagePrice))).toString()
                }
            })
            setDelegationState(delegations)
            await dispatch(editUserInfo({ delegationsAveragePriceInfo }))
            setIsLoading0(false)
        } else {
            setIsLoading0(false)
        }

        if (responses[1] && responses[1].result.length !== 0) {
            const withdrawals = calculateTotalWithdrawals(responses[1] as TxnLogEvents)
            setWithdrawnReward(withdrawals.toString())
            setIsLoading1(false)
        } else {
            setIsLoading1(false)
        }
        setFetching(false)

    }

    const modifyValueString = (str: string) => {
        const indexOfDot = str.indexOf(".")
        return str.slice(0, indexOfDot + 6)
    }

    useEffect(() => {
        if (addr && !fetching) {
            fetchDelegationInfo(addr)
        }

    }, [addr, blockNumber])

    useEffect(() => {
        setDelTotal(value);
    }, [value])

    const renderTableBody0 = () => {
        if (value === 0) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={6} sx={{ textAlign: "center", padding: 0, borderBottom: 0 }}>
                            <Paper sx={{ borderRadius: 0 }}>
                                <Typography variant="body2">
                                    {"----You do not have any ongoing delegations----"}
                                </Typography>
                            </Paper>
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }
        return (
            <TableBody>
                {delegationState && Object.keys(delegationState).map((validator, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>
                            <Box sx={{ display: "block" }}>
                                <Typography variant="subtitle1">
                                    {delegationState[validator].moniker}
                                </Typography>
                                <Typography variant="subtitle2" sx={{ fontWeight: "250" }}>
                                    {validator}
                                </Typography>
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <Box sx={{ display: "block" }}>
                                    <Typography variant="subtitle1">
                                        {delegationState[validator].amount!.slice(0, 12)}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ fontWeight: "250" }}>
                                        {`$${modifyValueString(delegationState[validator].value!)}`}
                                    </Typography>
                                </Box>
                                <Box
                                    component="img"
                                    sx={{
                                        height: "28px",
                                        marginLeft: "5px",
                                        alignSelf: "center"
                                    }}
                                    src={Constants.Tokens.Logos.FX}
                                />
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItmes: "center" }}>
                                <Box sx={{ display: "block" }}>
                                    <Typography variant="subtitle1">
                                        {delegationState[validator].reward!.slice(0, 12)}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ fontWeight: "250" }}>
                                        {`$${modifyValueString(delegationState[validator].rewardValue!)}`}
                                    </Typography>
                                </Box>
                                <Box
                                    component="img"
                                    sx={{
                                        height: "28px",
                                        marginLeft: "5px",
                                        alignSelf: "center"
                                    }}
                                    src={Constants.Tokens.Logos.FX}
                                />
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Typography variant="subtitle1">
                                {`$${modifyValueString(delegationState[validator].value!)}`}
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Typography variant="subtitle1" sx={{ color: `${delegationState[validator].gainOrLoss![0] === "-" ? "red" : "green"}` }}>
                                {`$${modifyValueString(delegationState[validator].gainOrLoss!)}`}
                            </Typography>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        )
    }

    const renderTableBody1 = () => {
        if (withdrawnReward === 0 && totalDelegationRewards === 0) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={6} sx={{ textAlign: "center", padding: 0, borderBottom: 0 }}>
                            <Paper sx={{ borderRadius: 0 }}>
                                <Typography variant="body2">
                                    {"----You do not have any withdrawn and delegation rewards----"}
                                </Typography>
                            </Paper>
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        } else {
            return (
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell>
                            <Box sx={{ display: "flex", alignItmes: "center" }}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: "28px",
                                        marginRight: "5px",
                                        alignSelf: "center"
                                    }}
                                    src={Constants.Tokens.Logos.FX}
                                />
                                <Box sx={{ display: "block" }}>
                                    <Typography variant="subtitle1">
                                        {withdrawnReward.toString().slice(0, 12)}
                                    </Typography>
                                </Box>
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItmes: "center" }}>
                                <Box sx={{ display: "block" }}>
                                    <Typography variant="subtitle1">
                                        {totalDelegationRewards}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ fontWeight: "250" }}>
                                        {`$${(Number(totalDelegationRewards) * fxPrice).toFixed(5)}`}
                                    </Typography>
                                </Box>
                                <Box
                                    component="img"
                                    sx={{
                                        height: "28px",
                                        marginLeft: "5px",
                                        alignSelf: "center"
                                    }}
                                    src={Constants.Tokens.Logos.FX}
                                />
                            </Box>
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            )
        }
    }

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: theme.dark.palette.background.paper }}>
                        <TableCell size="small" sx={{ borderTop: 1, borderTopWidth: 1, borderTopColor: theme.dark.palette.background.default }}>
                            <Typography variant="h6">
                                {delegationTableData.group}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell size="small">{delegationTableData.headings[0]}</StyledTableCell>
                        {delegationTableData.headings.slice(1).map((heading, index) => {
                            return <StyledTableCell key={index} size="small" align="right">{heading}</StyledTableCell>
                        })}
                    </TableRow>
                </TableHead>
                {isLoading0
                    ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={6} sx={{ textAlign: "center", padding: 0, borderBottom: 0 }}>
                                <Paper sx={{ borderRadius: 0 }}>
                                    <Box display={"grid"} sx={{ width: "100%" }}>
                                        <Typography variant="body2">
                                            Loading...
                                        </Typography>
                                        <LinearProgress color="inherit" />
                                    </Box>
                                </Paper>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    : renderTableBody0()
                }
            </Table>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell size="small" align="left">{delegationTableData.subHeadings[0]}</StyledTableCell>
                        <StyledTableCell size="small" align="right">{delegationTableData.subHeadings[1]}</StyledTableCell>
                    </TableRow>
                </TableHead>
                {isLoading1
                    ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={6} sx={{ textAlign: "center", padding: 0, borderBottom: 0 }}>
                                <Paper sx={{ borderRadius: 0 }}>
                                    <Box display={"grid"} sx={{ width: "100%" }}>
                                        <Typography variant="body2">
                                            Loading...
                                        </Typography>
                                        <LinearProgress color="inherit" />
                                    </Box>
                                </Paper>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    : renderTableBody1()
                }
            </Table>
        </Box>
    )
}

export default Delegations