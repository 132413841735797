import { Box, Paper, Typography, Modal, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state/ReduxHooks'
import { editUserInfo, selectUser } from 'state/userSlice'
import { Logout } from '@mui/icons-material';
import { UserInterface } from 'state/userSlice';
import { logout } from 'state/authSlice';
import { disconnect } from '@wagmi/core';
import AlertComp from 'components/Alert/Alert';
import { removeUser } from "../state/userSlice"

export const Profile = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  let walletAddress: string = ''
  if (user.walletAddress) walletAddress = `${user.walletAddress.substring(0, 5)}...${user.walletAddress.substring(user.walletAddress.length - 5)}`

  const [fieldInput, setFieldInput] = useState<string | null>('')
  const [open, setOpen] = useState(false)
  const [editField, setEditField] = useState<string | null>(null)

  const displayText = {
    'email': 'Email Address',
    'walletAddress': 'Wallet Address'
  }

  const handleEdit = (field: keyof UserInterface) => {
    setEditField(field)
    setFieldInput(user[field] as string)
    setOpen(true)
  }

  const handleClose = () => {
    setFieldInput('')
    setEditField(null)
    setOpen(false)
  }

  const submitEdit = async () => {
    if (editField) {
      const res = await dispatch(editUserInfo({ [editField]: fieldInput }))
      if (res.type === 'user/editUserInfo/fulfilled') {
        setFieldInput('')
        setEditField(null)
        setOpen(false)
      }

    }
  }

  const handleLogout = () => {
    dispatch(removeUser())
    dispatch(logout())
    disconnect()
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '80vh', justifyContent: 'space-around' }}>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="input-modal"
        aria-describedby="input for edit user info"
      >
        <Box>
          <Box sx={{top:'100px', position:'relative', width:'100%', display:'block'}}>
            <AlertComp />
          </Box>
          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            spacing: '20px'
          }}>
            <TextField label={`${editField}`}
              variant='outlined'
              fullWidth
              value={fieldInput}
              onChange={e => setFieldInput(e.target.value)}
              sx={{ display: 'block' }} />
            <Button variant="contained" onClick={() => submitEdit()} sx={{ marginLeft: 'auto', marginTop: '20px' }}>{`Confirm ${displayText[editField as keyof typeof displayText]}`}</Button>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          display: 'flex',
          width: '500px',
          flexDirection: 'column',
          marginTop: '36px',
        }}>
        <Typography fontWeight={600}>Account Details</Typography>
        <Paper sx={{ margin: '24px 0 24px 0', padding: '24px', display: 'flex', flexDirection: 'column', rowGap: '12px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontWeight={400}>Email</Typography>
            {user.email ?
              <Typography fontWeight={400}>{user.email}</Typography>
              : <Button onClick={() => handleEdit('email')}>Add Email</Button>}

          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Wallet
            <Box>
              <Typography fontWeight={400}>{walletAddress}</Typography>
            </Box>
          </Box>
          {/* <Button
            sx={{ alignSelf: 'end' }}
            onClick={() => disconnect()}>
            Disconnect Wallet
          </Button> */}
        </Paper>
        <Button color='error' onClick={handleLogout}><Logout sx={{ marginRight: "5px" }} />Logout</Button>
      </Box>
    </Box>
  )
}
