export const REGISTER_HEADER = "Register a New Account";
export const REGISTER_SUBHEADER = "Fill up the fields to get started";
export const NAME_HEADER = "Name";
export const EMAIL_HEADER = "Email";
export const PASSWORD_HEADER = "Password";
export const WALLET_HEADER = "Wallet Address";
export const CREATE_ACCOUNT = "Create an Account";
export const ACCEPT_TERMS_PRIVACY = "Please accept the Terms of Use and Privacy Policy before proceeding";
export const NAME_REQUIRED = "⚠ Name is a required field";
export const EMAIL_REQUIRED = "⚠ Email is a required field";
export const PASSWORD_REQUIRED = "⚠ Password is a required field";
export const WALLET_REQUIRED = "⚠ Wallet Address is a required field";
export const NAME_VALID = "Enter a valid name";
export const EMAIL_VALID = "Enter a valid email";
export const PASSWORD_VALID = "⚠ Password must be at least 8 characters";
export const WALLET_VALID = "⚠ Please enter a valid address starting with 0x";