import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import CardContent from "@mui/material/CardContent";
import LPIcon from "../components/LPIcon";
import { Constants } from "../constants";
import { FarmsSnapshot } from "../constants/FxSwapFarmsSnapshot";
import { getTokenMetaData2 } from "../utils/Token";
import { useAppSelector } from "../state/ReduxHooks";

interface FxSwapCardProps {
    snapshot: FarmsSnapshot;
    calculateTvl: (arg: number[]) => void;
}

const headings = Constants.FxFarmsSnapshot.FXFarmHeadings;

const FxSwapCard: React.FC<FxSwapCardProps> = ({ snapshot, calculateTvl }) => {
    const metrics = useAppSelector((state) => state.metrics);
    const aprArr = Object.values(metrics.metrics.fxswap.AllData.apr);
    const apyArr = Object.values(metrics.metrics.fxswap.AllData.apyDaily);
    const tvlArr = Object.values(metrics.metrics.fxswap.AllData.tvl);
    snapshot.farms.forEach((farm, index) => {
        farm.apr = aprArr[index];
        farm.apy = apyArr[index];
        farm.tvl = tvlArr[index];
    })
    const liquidityFarms = snapshot.farms.filter((farm) => {
        return farm.active === true
    }).map((farm) => ({
        ...farm,
        token0: getTokenMetaData2(farm.token0),
        token1: getTokenMetaData2(farm.token1)
    }));

    useEffect(() => {
        calculateTvl(liquidityFarms.map(farm => parseFloat(farm.tvl as string)))
    }, [liquidityFarms])

    return (
        <Box>
            <Card sx={{ backgroundColor: "#27283D", borderRadius: 4, overflowX: "auto" }}>
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell size="medium">
                                    <Typography variant="h5">
                                        {headings[0]}
                                    </Typography>
                                </TableCell>
                                {headings.slice(1).map((heading, index) => {
                                    return <TableCell key={index} size="medium" align="right">
                                        <Typography variant="h5">{heading}
                                        </Typography>
                                    </TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {liquidityFarms.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell size="small" sx={{ borderBottom: 0 }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <LPIcon
                                                token0Src={row.token0.logoUrl}
                                                token1Src={row.token1.logoUrl}
                                            />
                                            <Box sx={{ display: "block" }}>
                                                <Box>
                                                    <Typography variant="subtitle1">
                                                        {row.farmName}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="subtitle2" sx={{ fontWeight: "250" }}>
                                                    {`${(row.id).slice(0, 12)}...`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="right" sx={{ borderBottom: 0 }}>
                                        <Typography variant="h6">
                                            {`${Number(row.apr).toFixed(2)}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" sx={{ borderBottom: 0 }}>
                                        <Typography variant="h6">
                                            {`${Number(row.apy).toFixed(2)}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" sx={{ borderBottom: 0 }}>
                                        <Typography variant="h6">
                                            {`${Number(row.tvl).toLocaleString("en-US", { maximumFractionDigits: 2 })}`}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Box>
    )
}

export default FxSwapCard