import {getMasterChefV2Contract} from "./getContracts";
import {ethers} from "ethers";
import {FarmsState} from "../state/liquidityFarms/farmReducer";
import {PricesState} from "../state/prices/pricesReducer";
import {getSingleTokenPrice, getTokenMetaData2} from "./Token";

//assert non-null
const masterchefV2Contract = getMasterChefV2Contract()!;

/**
 * @returns The number of pools in the farm contract
 */
export const getPoolLength = async () => {
    const poolLength = await masterchefV2Contract.poolLength();
    return poolLength.toNumber();
}

/**
 * Gets the pending rewards of the staked rewards pool
 * @param _poolId Pool index of the staked rewards pool
 * @param _userAddress User's address used in the staking
 * @returns Pending rewards in Ether
 */
export const getPendingReward = async (_poolId: number, _userAddress: string) => {
    try {
        const pendingRewards = await masterchefV2Contract.pendingReward(_poolId, _userAddress);
        return Number(ethers.utils.formatEther(pendingRewards));
    } catch (error) {
        console.error("getPendingReward: Address is not supported on f(x)Core chain");
        return 0
    }
}

/**
 * Gets the user's information in the given rewards pool
 * @param _poolId
 * @param _userAddress
 * @returns An object containing the liquidity deposited (amount) and reward debt
 */
export const getUserInfo = async (_poolId: number, _userAddress: string) => {
    try {
        const userInfo = await masterchefV2Contract.userInfo(_poolId, _userAddress);
        return {
            amount: ethers.utils.formatEther(userInfo.amount),
            rewardDebt: ethers.utils.formatEther(userInfo.rewardDebt),
        }
    } catch (error) {
        console.error("getUserInfo: Address is not supported on f(x)Core chain");
        return {
            amount: "0",
            rewardDebt: "0"
        }
    }
}

/**
 * Gets the rewards pool info
 * @param _poolId Pool index of the rewards pool
 * @returns An object containing lpToken, allocPoint, lastRewardBlock, accRewardPerShare
 */
export const getPoolInfo = async (_poolId: number) => {
    return await masterchefV2Contract.poolInfo(_poolId);
}

/**
 *  Given the Farms state object, replaces each Token object in the Farm with
 *  an updated Token instance. Updates the logo and price of each Token
 * @param _farmsState The Farms state in Redux
 * @param _pricesState The Prices state in Redux
 * @param _userAddress The user address string
 * @returns An UpdatedFarmsPosition object containing user address and liquidity positions
 */
export const retrieveFarmPosMeta = (_farmsState: FarmsState, _pricesState: PricesState, _userAddress: string | null = null) => {
    if (_farmsState.farms != null) {
        let farms = _farmsState.farms;
        for (let i = 0; i < Object.keys(farms).length; i++) {
            farms = {
                ...farms,
                [i]: {
                    ...farms[i],
                    token0: getSingleTokenPrice(_pricesState, getTokenMetaData2(farms[i].token0)),
                    token1: getSingleTokenPrice(_pricesState, getTokenMetaData2(farms[i].token1)),
                }
            }
        }
        const farmPosUpdated: UpdatedFarmPositions = {
            userAddress: _userAddress,
            farms: Object.values(farms),
        }
        return farmPosUpdated
    } else {
        return null
    }
}

/**
 * @returns Rewards per block
 */
export const getRewardPerBlock = async () => {
    const rpb = await masterchefV2Contract.rewardPerBlock();
    return Number(ethers.utils.formatEther(rpb));
}

