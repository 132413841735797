import { createAction } from "@reduxjs/toolkit";
import { number } from "prop-types";
//import { TokenList } from "../../sdk/core" //yet to implement.

export type PopupContent =
    |
    {
        txns: {
            hash: string
            success: boolean
            summary?: string
        }
    }
// | 
//     {
//         listUpdate: {
//             listUrl: string
//             oldList: TokenList
//             newList: TokenList
//             auto: boolean
//         }   

//     }

export enum ApplicationModal {
    WALLET,
    SETTINGS,
    SELF_CLAIM,
    CHANGE_NETWORK,
    CLAIM_POPUP,
    MENU,
    DELEGATE,
    VOTE,
    POOL_OVERVIEW_OPTIONS,
}

export const updateBlockNumber = createAction<{ chainId: number; blockNumber: number }>("application/updateBlockNumber");
export const setOpenModal = createAction<ApplicationModal | null>("application/setOpenModal");
export const addPopup = createAction<{ key?: string; removeAfterMs?: number | null; content: PopupContent }>(
    'application/addPopup'
)
export const removePopup = createAction<{ key: string }>('application/removePopup')