import { createReducer } from "@reduxjs/toolkit";
import { updatePrices, clearPrices } from "./pricesActions";

export interface TokenPrice {
    name: string,
    symbol: string,
    priceUSD: number,
    lastUpdated: string
}

export interface PricesState {
    tokenPrices: TokenPrice[] | null
}

const initialState: PricesState = {
    tokenPrices: null
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(clearPrices, (state) => {
            state.tokenPrices = null;
        })
        .addCase(updatePrices, (state, action) => {
            //Payload must be TokenPrice[]
            state.tokenPrices = action.payload;
        })
)