import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, store } from "./store";
import { PrivateNoteModel, requestError } from "./privateNote/privateNoteSlice";
import serveraxios from "config/ServerAxios";
import { logout } from "./authSlice";
import { DelegationsAveragePriceInfo } from "utils/Delegations";

export interface UserInterface {
    _id: string | null;
    name: string | null;
    email: string | null;
    walletAddress: string | null;
    notes: PrivateNoteModel[];
    seq: number | null;
    delegationsAveragePriceInfo?: DelegationsAveragePriceInfo
}

export interface UserState {
    user: UserInterface;
}

export interface UpdateSuccess {
    message: string
    user: UserInterface
}

const initialState: UserState = {
    user: {
        _id: null,
        name: null,
        email: null,
        walletAddress: null,
        notes: [],
        seq: null,
    },
}

export const fetchAndUpdateUser = createAsyncThunk("user/fetchAndUpdateUser", async (_, thunkAPI) => {
    const state = store.getState()
    const { data: data } = await serveraxios.get(
        "/api/user",
        {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${state.auth.auth.accessToken}`
            }
        }
    );
    console.log(data);
    if (data) thunkAPI.dispatch(setUser(data.user))
    return data
});

export const editUserInfo = createAsyncThunk<
    UpdateSuccess,
    Partial<UserInterface>,
    {
        rejectValue: requestError
    }
>("user/editUserInfo", async (fieldToEdit: Partial<UserInterface>, thunkAPI) => {
    try {
        const state = store.getState()
        const res = await serveraxios.put(
            `/api/user/${state.user.user._id}`,
            { fieldToEdit },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${state.auth.auth.accessToken}`
                }
            }
        );
        if (res.status === 200) thunkAPI.dispatch(setUser(res.data.user))
        return res.data.user
    } catch (error: any) {
        return thunkAPI.rejectWithValue({
            status: error.response.data.status,
            message: error.response.data.message,
        });
    }

});

//(The new way to create reducers)
//createSlice uses createAction and createReducer internally
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = { ...state.user, ...action.payload }
        },
        removeUser: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logout.fulfilled, (state, action) => {
                state.user = initialState.user
            })
    }
});

//Action creators generated for each case reducer func
export const { setUser, removeUser } = userSlice.actions;

//Selector for user reducer
export const selectUser = (state: RootState) => state.user.user;

//Reducer function for userSlice (aliased: userReducer)
export default userSlice.reducer;