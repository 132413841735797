import React, { useState, useEffect, useMemo } from "react";
import AccordionTable from "./AccordionTable";
import Box from "@mui/material/Box";
import PortfolioData from "./dataComponents/PortfolioData";
import { getPoolData, calcTotalValue, getFarmData, matchPoolAndFarm } from "../utils/FxSwap";
import { useAppSelector } from "../state/ReduxHooks";
import { Constants } from "../constants"

interface PortfolioProps {
    liquidityData: UpdatedLiquidityPositions,
    farmsData: UpdatedFarmPositions
}

const Portfolio: React.FC<PortfolioProps> = ({ liquidityData, farmsData }) => {
    //const testAddr = "0x768E21C9067f402eE5d414dA33bc0bfE052CC6Cf";
    //const testAddr = "0x93975e1e841079541c654d8841db9a7d2d17ac58";
    //const testVal = "fxvaloper1qjz9334grynx8lg6ae9vj2fnktgj7u0qvq3szl";
    const [totalValue, setTotalValue] = useState<number>(0);
    const [poolRows, setPoolRows] = useState<PoolData[]>(Constants.Data.defaultPoolRow);
    const [farmRows, setFarmRows] = useState<FarmData[]>(Constants.Data.defaultFarmRow);
    //const [delegation, setDelegation] = useState<DelegationData>(Constants.Data.defaultDelegations)
    const [isLoading, setIsLoading] = useState(true);
    const [delTotal, setDelTotal] = useState<number>(0);

    const prices = useAppSelector((state) => state.prices.tokenPrices);
    let fxPrice = 0;
    if (prices) {
        const priceObj = prices.find(price => price.symbol === "FX")
        if (priceObj) {
            fxPrice = priceObj.priceUSD;
        }
    }

    useEffect(() => {
        if (liquidityData.liquidityPositions) {
            const updatedRows = getPoolData(liquidityData.liquidityPositions);
            setPoolRows(updatedRows);
        }
    }, [liquidityData])

    useEffect(() => {
        if (farmsData.farms) {
            const updatedRows = getFarmData(farmsData.farms, fxPrice);
            if (updatedRows.length > 0) {
                setFarmRows(updatedRows);
            }
        }
    }, [farmsData, fxPrice])

    const poolRowsMatched = useMemo(() => {
        return matchPoolAndFarm(poolRows, farmRows)
    }, [poolRows, farmRows]);



    useEffect(() => {
        const totaledValue = calcTotalValue(poolRowsMatched, farmRows) + delTotal;
        setTotalValue(totaledValue)
    }, [poolRowsMatched, farmRows, totalValue, delTotal]);

    useEffect(() => {
        const interval = setTimeout(() => setIsLoading(false), 15000);
        return () => clearTimeout(interval);
    }, []);

    const poolData = {
        group: "Liquidity",
        headings: ["Positions", "Your Total Pool Tokens", "Value", "Unrealized +/-"],
        data: poolRowsMatched
    }

    const farmData = {
        group: "Farm",
        headings: ["Pools", "Deposit", "Rewards", "Value"],
        data: farmRows
    }

    return (
        <AccordionTable
            iconComponent={
                <Box
                    component="img"
                    sx={{
                        height: "32px",
                        width: "32px",
                    }}
                    src={"https://s2.coinmarketcap.com/static/img/coins/64x64/3884.png"}
                />
            }
            title={"Portfolio"}
            value={`$ ${totalValue.toFixed(5)}`}
            dataComponent={
                <PortfolioData
                    poolGroup={poolData}
                    farmGroup={farmData}
                    setDelTotal={setDelTotal}
                />
            }
            loading={isLoading}
        />
    )
}

export default Portfolio;