import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { useConnect, useAccount, useDisconnect } from "wagmi";
import { useTheme } from '@mui/material/styles';
import ExtensionIcon from '@mui/icons-material/Extension';
import WALLETCONNECT_ICON_URL from "../assets/images/walletConnectIcon.svg";
import coinbaseIcon from "../assets/images/coinbaseIcon.png";
import METAMASK_ICON_URL from "../assets/images/metamask.png";
import { useAppDispatch } from "state/ReduxHooks";
import { walletLogin } from "state/authSlice";
import { useIsMounted } from "hooks/useIsMounted";

interface Web3WalletProp {
    setError: (val: boolean) => void;
    setErrorMsg: (val: string) => void;
}

//Parent component that inherits Web3Wallet should have state for error and error message
const Web3Wallet: React.FC<Web3WalletProp> = ({ setError, setErrorMsg }) => {
    const dispatch = useAppDispatch()
    const isMounted = useIsMounted()
    const theme = useTheme();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: theme.palette.background.default,
        border: '1px #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 4,
    };

    const [openWalletModal, setOpenWalletModal] = useState<boolean>(false);
    const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
    const { address, connector, isConnected } = useAccount({
        onConnect() {
            setError(false);
            setErrorMsg("");
            handleCloseModal();
        },
    });
    const { disconnect } = useDisconnect();

    const handleOpenModal = () => {
        setOpenWalletModal(true);
    }

    const handleCloseModal = () => {
        setOpenWalletModal(false);
    }

    useEffect(()=>{
        if(address){
            dispatch(walletLogin(address))
        }
    },[address])

    useEffect(() => {
        if (error) {
            setError(true);
            setErrorMsg(error.message);
        }
    }, [error])

    const renderModal = () => {
        if (isConnected && connector) {
            let logo: any;
            if (connector.name === "WalletConnect") {
                logo = <img src={WALLETCONNECT_ICON_URL} width={"24px"} />
            } else if (connector.name === "MetaMask") {
                logo = <img src={METAMASK_ICON_URL} width={"24px"} />
            } else {
                logo = <ExtensionIcon fontSize={"medium"} />
            }
            const addressTruncated = address!.substring(0, 7) + " . . . " + address!.slice(-5)
            return (
                <Box sx={style} display={"grid"}>
                    <Typography variant="h4" paddingBottom={"5px"}>
                        Wallet Connected
                    </Typography>
                    <Box
                        display={"grid"}
                        sx={{
                            border: "1px solid #424549",
                            borderRadius: 1,
                            padding: "5px"
                        }}
                    >
                        <Box display={"flex"}>
                            {logo}
                            <Box sx={{ marginLeft: "5px" }}>
                                <Typography variant={"subtitle1"}>
                                    {addressTruncated}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "5px" }}
                        >
                            <Typography variant="subtitle2" color="#424549">
                                {`Connected with ${connector.name}`}
                            </Typography>
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{ borderRadius: 4 }}
                                onClick={() => disconnect()}
                            >
                                Disconnect
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )
        }
        return (
            <Box sx={style} display={"grid"}>
                <Typography variant="h4" paddingBottom={"5px"}>
                    Connect with Wallet
                </Typography>
                <Box paddingBottom={"10px"}>
                    <Paper elevation={3}>
                        <Box padding={"8px"}>
                            <Typography fontSize={14} variant="caption">
                                By connecting a wallet, you agree to Function X{' '}
                                <Link href="https://functionx.io/home">Terms of Service</Link> and acknowledge that you
                                have read and understand the{' '}
                                <Link href="https://functionx.io/home">Function X protocol disclaimer</Link>.
                            </Typography>
                        </Box>
                    </Paper>
                </Box>
                {connectors.map((connector) => (
                    <Button
                        disabled={!connector.ready}
                        key={connector.id}
                        onClick={() => connect({ connector })}
                        startIcon={
                            connector.name === "WalletConnect" ? <img src={WALLETCONNECT_ICON_URL} width={"18px"} />
                                : connector.name === 'Coinbase Wallet' ? <img src={coinbaseIcon} width={"18px"} />
                                : connector.name === 'MetaMask' ? <img src={METAMASK_ICON_URL} width={"18px"} />
                                : <ExtensionIcon />
                        }
                        sx={{ alignItems: "center" }}
                    >
                        {connector.name}
                        {isMounted && !connector.ready && ' (unsupported)'}
                        {isLoading &&
                            connector.id === pendingConnector?.id &&
                            ' (connecting...)'}
                    </Button>
                ))}
            </Box>
        )
    }

    return (
        <Box>
            <Button
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
                onClick={handleOpenModal}
            >
                Connect Wallet
            </Button>
            <Modal open={openWalletModal} onClose={handleCloseModal}>
                {renderModal()}
            </Modal>
        </Box>
    )
}

export default Web3Wallet;