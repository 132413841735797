import React, { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { network } from '../connectors';
import { useActiveWeb3React, useEagerConnect, useInactiveListener } from '../hooks/web3';
import { NetworkContextName } from '../constants/Misc';

export default function Web3ReactManager({ children }: { children: JSX.Element }) {
    // const { active } = useActiveWeb3React();
    const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React(NetworkContextName);

    // try eager connect to an injected provider, if it exists and has granted access already
    const triedEager = useEagerConnect();
    console.log(`Tried Eager: ${triedEager}`)
    console.log(`Network active: ${networkActive}`)

    //after trying eager connect, if network connect is not active or error state, activate
    useEffect(() => {
        if (triedEager && !networkActive && !networkError ) {
            activateNetwork(network)
            console.log('activating')
        }
    }, [triedEager, networkActive, networkError, activateNetwork, ])

    // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
    useInactiveListener(!triedEager)

    // on page load, do nothing until we've tried to connect to the injected connector
    if (!triedEager) {
        return null
    }

    return children;
}